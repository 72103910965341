<template>
<value-object ref="vo"
  value-type="toml"
  config-url-prefix="/cf/gc/get/"
  :refresh-route-path="['/vogc']"
  :votype="306"
  @tab-change="onTabChange">
  <template slot="top">
    <help-value-object-g-c  class="markdown-body" />
  </template>
  <template slot="buttons" slot-scope="props">
    <button class="button is-warning" @click="onEditClick306(props.row)">修改</button>
  </template>
  <template slot="tabitems">
    <b-tab-item label="增加">
      <value-object-editor-j-t ref="editorAsAdd306" value-type="toml" @edit="onEditSave" />
    </b-tab-item>
    <b-tab-item label="编辑" :disabled="!inEdit">
      <value-object-editor-j-t ref="editorAsEdit306" is-edit value-type="toml" @edit="onEditSave" />
    </b-tab-item>
  </template>
</value-object>
</template>

<script>
/**
 * 专门编辑频繁访问的配置 votype=306
 */
import ValueObject from '@/components/vo/ValueObject'
import ValueObjectEditorJT from '@/components/vo/ValueObjectEditorJT'
import HelpValueObjectGC from '@/help/vogc.md'

const REF_NAMES = [null, 'editorAsAdd306', 'editorAsEdit306']

export default {
  name: 'vogc',
  components: { ValueObject, ValueObjectEditorJT, HelpValueObjectGC },
  data () {
    return {
      inEdit: false
    }
  },
  methods: {
    onTabChange ({ to, from }) {
      const thisRef = this.$refs[REF_NAMES[from]]
      console.log('onTabChange %s %s %s', to, from, thisRef)
      if (thisRef) {
        thisRef.clearValues()
      }
      this.inEdit = to === 2
    },
    onEditClick306 (vo) {
      this.$refs.editorAsEdit306.setValues(vo)
      this.$refs.vo.tabIndex = 2
    },
    onEditSave (vo) {
      this.$refs.vo.onEditSave(vo)
    }
  }
}
</script>
